<template>
    <div class="pagination-wrapper">
        <form class="pure-form" style="text-align: right;" v-if="total > 0">
            <span style="padding: 0.5em 1em;">Total Record: {{ total }}, Total Page: {{Math.ceil(total/20)}}</span>
            <input type="number" placeholder="Page" style="width: 80px; margin-right: 0.5em;" v-model="innerPage"/>
            <button type="submit" class="pure-button pure-button-primary" @click="gotoPage()">Confirm</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: ["size", "page", "total"],
    data() {
        return {
            innerPage: this.page,
        }
    },
    methods: {
        gotoPage() {
            console.log('innerpage', this.innerPage)
            this.$emit('gotoPage', this.innerPage)
        },
    },
    watch: {
        page(val) {
            this.innerPage = val;
        }
    }
}
</script>

<style scoped>
.pagination-wrapper {
    margin-top: 1em;
    font-size: 14px;
}
</style>